<template>
  <div class="w-full">
    <SuccessButton :disabled="!$atividades.permissoes.includes(14)" class="mr-6" @func="modalCadastroMetodo = true"></SuccessButton>
    <SideBar
      title="Cadastrar metodo"
      @hide="modalCadastroMetodo = false"
      :active="modalCadastroMetodo"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
          <div  style="display:inline-block" class=" w-full">
            <vs-input
              class="inputx w-full"
              label-placeholder="Nome"
              v-model="metodo.nome"
              name="nome"
              v-validate="'required'"
            />
            <span
              class="ml-3 text-danger text-sm"
              v-show="errors.has('nome')">
            {{$validators.empty}}</span>
          </div>
          </vs-col>
        </vs-row>
        <div class="w-full">
            <div style="display:inline-block;margin-right:20px;">
            <label style="color: black; font-size: 13px;">Metodologia</label><br>
            <el-select filterable clearable class="select-info"
              placeholder="Selecione..."
              name="metodologia"
              v-validate="'required'"
              v-model="metodo.id_metodologia"
              :popper-append-to-body="false">
              <el-option v-for="metodologia in metodologias"
                  class="select-info"
                  :value="metodologia.id"
                  :label="metodologia.nome"
                  :key="metodologia.id">
              </el-option>
            </el-select>
            <vs-row>
              <span
                class="text-danger text-sm"
                v-show="errors.has('metodologia')">
              {{$validators.empty}}</span>
            </vs-row>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('undMedida')"
          >{{ $validators.select }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-switch color="success" v-model="metodo.isca"></vs-switch>
          <p class="ml-4">Isca</p>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalCadastroMetodo",
  props: { metodologias: Array },
  data() {
    return {
      metodo: {},
      modalCadastroMetodo: false,
      error: false
    };
  },
  methods: {
   async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.cadastrarMetodo()
          } else {
            this.$vs.notify({
              title:'Preencha todos os campos',
              text:'Os campos não devem ser vazios!',
              color:'danger'
            })
          }
        })
      })
    },
    async cadastrarMetodo() {
      this.$vs.loading();
      try {
        const res = await this.$http.post("metodo", this.metodo);
        let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'cadastrar',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Cadastrar método N°' + res.id}
          await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalCadastroMetodo = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.$vs.loading.close();
      }
    }
  },

  components: components
};
</script>

<style></style>
