<template>
  <div>
    <EditButton :disabled="!$atividades.permissoes.includes(14)" @func="modalEditarMetodo = true"></EditButton>
    <SideBar
      title="Editar metodo"
      @hide="cancelar"
      @excluir="excluirMetodo"
      @status="mudarStatusMetodo"
      :active="modalEditarMetodo"
      :item="metodo"
      :disabled="!$atividades.permissoes.includes(15)"
      size="small"
      textSucces="Atualizar"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <div  style="display:inline-block" class=" w-full">
            <vs-input
              class="inputx w-full"
              label-placeholder="Nome"
              v-model="metodo.nome"
              name="nome"
              v-validate="'required'"
            />
            <span
              class="ml-3 text-danger text-sm"
              v-show="errors.has('nome')">
            {{$validators.empty}}</span>
          </div>

          </vs-col>
          <vs-col vs-w="12">
            <div style="display:inline-block;margin-right:20px;">
            <label style="color: black; font-size: 13px;">Metodologias</label><br>
            <el-select filterable clearable class="select-info"
              placeholder="Selecione..."
              v-model="metodo.id_metodologia"
              v-validate="'required'"
              :popper-append-to-body="false">
              <el-option v-for="metodologia in metodologias"
                  class="select-info"
                  :value="metodologia.id"
                  :label="metodologia.nome"
                  :key="metodologia.id">
              </el-option>
            </el-select>
            <vs-row>
              <span
                class="text-danger text-sm"
                v-show="errors.has('metodologia')">
              {{$validators.empty}}</span>
            </vs-row>
          </div>
          </vs-col>
          <vs-col vs-w="12" class="w-full flex mt-4">
            <vs-switch color="success" v-model="metodo.isca"></vs-switch>
            <p class="ml-4">Isca</p>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarMetodo",
  props: { metodo: Object, metodologias: Array, associadas: Array },
  data() {
    return {
      modalEditarMetodo: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.metodo);
    this.originalData = this.metodo;
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.editarMetodo()
          } else {
            this.$vs.notify({
              title:'Preencha todos os campos',
              text:'Os campos não devem ser vazios!',
              color:'danger'
            })
          }
        })
      })
    },
    async editarMetodo() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`metodo/${this.metodo.id}`, this.metodo);
        let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'atualizar',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Edição do metodo N°' + res.id}
          await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarMetodo = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async excluirMetodo() {
      this.$vs.loading();
      try {
        await this.$http.delete(`metodo/${this.metodo.id}`);
        let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'excluir',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Exclusão do metodo N°' + this.metodo.id}
          await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusMetodo() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`metodo/${this.metodo.id}`, {
          lixeira: !this.metodo.lixeira
        });
        let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'atualizar',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Mudança do status do método N°' + res.id}
          await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    syncMetodologias(metodologias) {
      this.associadas = metodologias;
    },
    cancelar() {
      Object.assign(this.metodo, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarMetodo = false;
    }
  },
  components: components
};
</script>

<style></style>
