<template>
  <div class="w-full">
    <vs-table
      maxHeight="67vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="metodos"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Métodos de organização dos produtos</h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroMetodo
              @update="getMetodos"
              :metodologias="metodologias"
            />
          </vs-col>
        </vs-row>
      </template>
      <template slot="thead">
        <vs-th sort-key="nome">Nome</vs-th>
        <vs-th sort-key="qtd_metodos">Metodologia</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>
          <vs-td :data="data[indextr].id_metodologia">
            <vs-chip color="primary"
              >{{ data[indextr].nome_metodologia }}
            </vs-chip>
          </vs-td>
          <vs-td class="actions">
            <ModalEditarMetodo
              @update="getMetodos"
              :metodologias="metodologias"
              :associadas="data[indextr].metodologias"
              :metodo="data[indextr]"
            ></ModalEditarMetodo>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="metodos.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum metodo cadstrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroMetodo from "./cadastro.vue"
import ModalEditarMetodo from "./editar.vue"

export default {
  data () {
    return {
      metodos: [],
      metodologias: []
    }
  },
  methods: {
    async getMetodos () {
      this.$vs.loading()
      try {
        this.metodos = await this.$http.get(`metodo`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async getMetodologias () {
      this.$vs.loading()
      try {
        this.metodologias = await this.$http.get(`metodologia`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
  },
  components: {
    ModalCadastroMetodo,
    ModalEditarMetodo
  },
  mounted () {
    this.getMetodos()
    this.getMetodologias()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
